import React from 'react';
import Scrollspy from 'react-scrollspy';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import overLayPic from '../assets/images/overlay.png';
import Footer from '../components/Footer';
import { Link } from 'gatsby';

const sections = [
  { id: 'one', bg: require('../assets/images/pic06.png') },
  { id: 'two', bg: require('../assets/images/pic02.jpg') },
  { id: 'three', bg: require('../assets/images/pic03.jpg') },
];
 
const AboutPage = () => (
  <Layout >
      

      <section id="one" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[0].bg} alt="" />
        </span>
        <div className="content">
        <img src={require("../assets/images/sp2.jpg")} style={{width:"350px", borderRadius:"1%"}}/>
          <header className="major">
            <h2 className="squish">Samantha Piro
              <p style={{position:"relative", top:-20, fontSize:"0.50em"}}>MA LLPC RYT200</p>
            </h2>
          </header>
          <p style={{textAlign:"left"}}>
          As a small child I believed myself to be “too sensitive” or “too emotional”. Everything and anything could easily upset me, and I began to adapt the belief that my emotions were unhelpful and too scary to face so I attempted to shut down this emotional aspect of the Self and in doing so went down a road of hurt, pain, and loneliness. In my attempt to “turn off” the emotional Self, I discovered that all I was doing was abandoning myself, and soon I began a journey of self-healing, self-discovery, and self-cultivating.
          </p>
          <p style={{textAlign:"left"}}>
          I found healing in feeling - in accepting the feelings about who I am, the feelings about where I am, and the feelings I have about my feelings. The key to the freedom from the pain has been the acceptance of the Self rather than the denial of it.
          </p>
          <p style={{textAlign:"left"}}> I believe we all deserve a space to feel safe, a space to exist, a space to be, a space to explore any and all aspects of ourselves. Sometimes the healing journey (a life-long journey) can be challenging to endure, and I do not believe we have to endure it alone. We all encounter suffering, pain, and loss. As a fellow traveler on this healing journey, I would be honored to be a person who holds this space for you as you unravel the many complexities of what it is like to be you.</p>
        </div>
        <Scroll type="id" element="two">
          <a href="#two" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>

      
        </section>


      <section id="two" className="main special">
      <div style={{marginBottom:"35px"}} className="container">
        <span className="image fit primary">
          <img src={sections[1].bg} alt="" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Naming Sacred Garden</h2>
          </header>
          <p style={{textAlign:"left"}}>
          One day during a meditation, I saw myself walking through an open, white space. I came to a door, and with no prompting, I instinctively knew this was a doorway to my heart. Not the physical, blood-pumping sense of the word heart, but the access to the love that exists within the physical body. I opened the door to discover a vast, limitless garden - surrounded by luscious greenery and an abundance of flowers. I was instantly reminded that I am sacred. I was reminded how to love from the inside out, and to always be cultivating my sense of self.
          </p>
          <p>
          <i>Sacred Garden, LLC was founded by Samantha Piro in loving memory of her grandmother, Mary DeCaussin, who shared her love through gardening and cultivating all things beautiful.</i>
          </p>
        </div>
        
        <div className="row">
                
                <div className="col-6 col-12-medium">
                  <a href="/#two" className="button primary" style={{paddingLeft:"3em", paddingRight:"3em"}}>Services</a>
                </div>
                
                
                <div className="col-6 col-12-medium">
                  
                   <Link to="/#footer" className="button primary">Contact Me</Link> 
                  
                </div>
                
              </div>
          
        
      </div>
      <Footer />
      </section>
        <Scrollspy
        items={sections.map(s => s.id)}
        currentClassName="active"
        offset={-250}
        componentTag={'div'}
        >
        {sections.map(s => {
            return (
            <div
                key={s.id}
                className="main-bg"
                id={`${s.id}-bg`}
                style={{ backgroundImage: `url(${overLayPic}), url(${s.bg})` }}
            />
            );
        })}
    </Scrollspy>
        
  </Layout>
);

export default AboutPage;
